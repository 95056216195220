import React from "react"
import { graphql } from "gatsby"
import PostsCards from "../components/Post"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Box, Container, Grid, Typography } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  }
}))
export default function Index({ data }) {
  const classes = useStyles()

  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Casos de éxito</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="90%" m="auto" py={2}>
          <Box mt={4} py={2} mb={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Casos de éxito</b>
            </Typography>
          </Box>
          <Grid container spacing={2} justify="space-around">
            {posts
              .filter(post => post.node.frontmatter.title.length > 0)
              .map(({ node: post }) => {
                console.log(post)
                return (
                  <PostsCards
                    title={post.frontmatter.title}
                    subtitle={post.excerpt}
                    path={post.frontmatter.path}
                  />
                )
              })}
          </Grid>
        </Box>
      </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`
