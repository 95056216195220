import React from "react"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core"
import { Link } from "gatsby"
import Acapulco from "../../static/Acapulco/Logo.jpg"

const useStyles = makeStyles({
  media: {
    height: 170
  },
  linkClassName: {
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  }
})

export default function PostsCards({ title, img, path, subtitle }) {
  const classes = useStyles()
  // let ImagesSet = img === Acapulco ? Acapulco : ""
  return (
    <Grid item lg={4} md={6} sm={11}>
      <Box px={2}>
        <Card>
          <Link to={path} className={classes.linkClassName}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={Acapulco}
                title={title}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="h2"
                  color="primary"
                >
                  {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {subtitle}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Box>
    </Grid>
  )
}
